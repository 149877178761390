import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateBalance } from '../store/store';

const WalletBalance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { bal, win } = useSelector(state => state.game);

  const handleAddMoney = () => {
    navigate('/add-money');
  };

  const handleWithdraw = () => {
    navigate('/withdraw');
  };

  return (
    <div style={styles.mainContainer}>
      <span style={styles.title}>Wallet Balance</span>
      <div style={styles.container}>
        <div style={styles.balanceContainer}>
          <span style={styles.value}>{bal}</span>
          <span style={styles.label}>Balance</span>
        </div>
        <div style={styles.divider}></div>
        <div style={styles.balanceContainer}>
          <span style={styles.value}>{win}</span>
          <span style={styles.label}>Winnings</span>
        </div>
      </div>
      <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={handleAddMoney}>MORE SPIN</button>
        <button style={styles.button} onClick={handleWithdraw}>WITHDRAW</button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    height: '100px',
    padding: '10px',
    borderRadius: '5px',
  },
  mainContainer: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#1976D2',
    color: 'white',
    paddingTop: '10px',
    paddingBottom: '5px',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '0 0 40px 40px',
  },
  divider: {
    width: '1px',
    height: '60%',
    backgroundColor: 'white',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    marginBottom: '10px',
  },
  button: {
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '20px',
    border: 'none',
    padding: '10px 20px',
    fontWeight: 'bold',
  },
  title: {
    fontSize: '22px',
    fontWeight: 'bold',
  },
  balanceContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    marginRight: '5px',
  },
  value: {
    fontSize: '25px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
};

export default WalletBalance;
