import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import AddMoney from './screens/addMoney';
import SpinTheWheelGame from './screens/home';
import Withdraw from './screens/withdraw';
import SetValues from './screens/setValues';
import { AudioProvider } from './contexts/AudioContext';

function App() {
  return (
    <AudioProvider>
      <Router>
        <Routes>
          <Route path="/" element={<SpinTheWheelGame />} />
          <Route path="/add-money" element={<AddMoney />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/set-values" element={<SetValues />} />
        </Routes>
      </Router>
    </AudioProvider>
  );
}

export default App;
