import { useEffect } from 'react';
import store from '../store/store'; // Import the store directly

class SpinLogic {
  constructor() {
    this.random = Math.random;
  }

  getRandom() {
    let arr;
    const state = store.getState().game;
    const { bal, win } = state;

    if (bal <= 50 && win <= 300) {
      arr = this.getmidvalue();
    } else if (win > 300 && win < 321) {
      arr = this.getplus();
    } else if (win > 320 && win < 341) {
      arr = this.getplus();
    } else if (win > 340 && win < 361) {
      arr = this.getplus();
    } else if (win > 360 && win < 381) {
      arr = this.getplus();
    } else if (win > 380 && win < 401) {
      arr = this.getplus2();
    } else if (win > 400 && win < 431) {
      arr = this.getplus2();
    } else if (win > 430 && win < 441) {
      arr = this.getplus1();
    } else if (win > 440 && win < 451) {
      arr = this.getplus2();
    } else if (win > 450 && win < 461) {
      arr = this.getplus3();
    } else if (win > 460 && win < 500) {
      arr = this.getminus();
    } else if (win > 500) {
      arr = this.getminus();
    } else if (bal > 50 && win <= 300) {
      arr = this.getplus();
    } else {
      arr = this.getplus4();
    }
    return arr[Math.floor(this.random() * arr.length)];
  }

  getminus() {
    return [4, 7];
  }

  getplus() {
    return [0, 1, 2, 5, 6, 8, 9, 12, 13, 14, 15];
  }

  getplus1() {
    return [0, 1, 2, 4, 5, 6, 7, 8, 9, 12, 13, 14, 15];
  }

  gethighvalue() {
    return [3, 10, 11];
  }

  getmidvalue() {
    return [3, 8, 11];
  }

  getplus2() {
    return [0, 1, 4, 5, 6, 7, 8, 9, 12, 13, 14];
  }

  getplus3() {
    return [0, 1, 4, 5, 6, 7, 8, 13, 14];
  }

  getplus4() {
    return [0, 1, 4, 5, 6, 7, 8, 13];
  }
}

export default SpinLogic;
