import { configureStore, createSlice } from '@reduxjs/toolkit';

// Function to load the initial state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('gameState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// Function to save the state to localStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('gameState', serializedState);
  } catch {
    // Ignore write errors
  }
};

const initialState = loadState() || {
  bal: 50, // Initial balance
  win: 0,   // Initial win amount
  mobileNo: '', // Initial mobile number
  name: '',     // Initial name
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    updateBalance: (state, action) => {
      state.bal = action.payload;
    },
    updateWin: (state, action) => {
      state.win = action.payload;
    },
    updateMobileNo: (state, action) => {
      state.mobileNo = action.payload;
    },
    updateName: (state, action) => {
      state.name = action.payload;
    },
  },
});

export const { updateBalance, updateWin, updateMobileNo, updateName } = gameSlice.actions;

const store = configureStore({
  reducer: {
    game: gameSlice.reducer,
  },
});

// Subscribe to store changes and save to localStorage
store.subscribe(() => {
  saveState(store.getState().game);
});

export const useStore = () => store;

export default store;
