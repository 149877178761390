import React from 'react';
// 2.38
const WheelPainter = ({ items, initialRotation = -Math.PI / 2.31, size }) => {
  const centerX = size / 2;
  const centerY = size / 2;
  const radius = size / 2;
  const arcAngle = (2 * Math.PI) / items.length;
  // Reverse the items arrays
  items = [...items].reverse();

  return (
    <div style={{ width: size, height: size, position: 'relative' }}>
      <svg width={size} height={size}>
        <g
          transform={`rotate(${(initialRotation * 180) / Math.PI} ${centerX} ${centerY})`}
        >
          {items.map((item, index) => {
            const startAngle = index * arcAngle;
            const endAngle = (index + 1) * arcAngle;
            const x1 = centerX + radius * Math.cos(startAngle);
            const y1 = centerY + radius * Math.sin(startAngle);
            const x2 = centerX + radius * Math.cos(endAngle);
            const y2 = centerY + radius * Math.sin(endAngle);

            const path = `M ${centerX} ${centerY} L ${x1} ${y1} A ${radius} ${radius} 0 0 1 ${x2} ${y2} Z`;

            const textAngle = startAngle + arcAngle / 2;
            const textX = centerX + (radius * 0.85) * Math.cos(textAngle);
            const textY = centerY + (radius * 0.85) * Math.sin(textAngle);

            return (
              <g key={index}>
                <path
                  d={path}
                  fill={index % 2 === 0 ? '#42A5F6' : '#90CAF8'}
                />
                <g
                  transform={`rotate(${(textAngle + Math.PI / 2) * (180 / Math.PI)} ${textX} ${textY})`}
                >
                  <text
                    x={textX}
                    y={textY}
                    fill="white"
                    fontSize="20"
                    fontWeight="bold"
                    textAnchor="middle"
                    alignmentBaseline="middle"
                  >
                    {item}
                  </text>
                </g>
              </g>
            );
          })}
        </g>
      </svg>
    </div>
  );
};

export default WheelPainter;
