import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateMobileNo, updateName, updateBalance, updateWin } from '../store/store';

const SetValues = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const name = searchParams.get('name');
    const mobileNo = searchParams.get('mobileNo');

    if (name) {
      dispatch(updateName(name));
    }
    if (mobileNo) {
      dispatch(updateMobileNo(mobileNo));
    }
    dispatch(updateBalance(50));
    dispatch(updateWin(0));

    navigate('/', { replace: true });
  }, [dispatch, location]);

  return null; // This component doesn't render anything
};

export default SetValues;
