import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, Image, TouchableOpacity, ScrollView, StyleSheet, SafeAreaView } from 'react-native';
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { updateBalance } from '../store/store';

const AddMoney = () => {
  const dispatch = useDispatch();
  const { bal, win } = useSelector(state => state.game);
  const [selectedSpins, setSelectedSpins] = useState('5');
  const [amount, setAmount] = useState('53');
  const [addList, setAddList] = useState(['55', '105', '150', '200', '300', '400']);

  const primaryColor = '#1976D2';
  const secondaryColor = '#FFFFFF';

  useEffect(() => {
    const updatedList = addList.map(getAmts);
    setAddList(updatedList);
    setAmount(updatedList[0]);
  }, []);

  const getAmts = (am) => {
    return `${parseInt(am) + Math.floor(Math.random() * 5)}`;
  };

  const pay = useCallback(debounce(async () => {
    if(window.Pay){
      window.Pay.postMessage(JSON.stringify({
        amount: amount,
        spins: selectedSpins,
      }));
    }
    console.log(`Paying ${amount} for ${selectedSpins} spins`);
  }, 300), [amount, selectedSpins]);

  const handleSpinSelection = useCallback((spins, price) => {
    setSelectedSpins(spins);
    setAmount(price);
  }, []);

  window.getAddMoney = function(spins){
    console.log(spins);
    dispatch(updateBalance(bal + spins));
    window.history.back();
  }

  const SpinOption = React.memo(({ spins, price }) => {
    const isSelected = selectedSpins === spins;
    return (
      <TouchableOpacity
        style={[
          styles.spinOption,
          isSelected && { backgroundColor: primaryColor }
        ]}
        onPress={() => handleSpinSelection(spins, price)}
      >
        <Text style={[styles.spinText, isSelected && { color: secondaryColor }]}>{spins}</Text>
        <Text style={[styles.spinSubText, isSelected && { color: secondaryColor }]}>Spin</Text>
        <Text style={[styles.priceText, isSelected && { color: secondaryColor }]}>₹ {price}</Text>
      </TouchableOpacity>
    );
  });

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollContent}>
        <img src="/assets/images/lucky_spin.png" style={styles.image} alt="Lucky Spin" />
        <Text style={styles.title}>Spin the Wheel</Text>
        <Text style={styles.subtitle}>Buy Spin For Winning More</Text>
        
        <View style={styles.optionsContainer}>
          <View style={styles.optionsRow}>
            <SpinOption spins="5" price={addList[0]} />
            <SpinOption spins="10" price={addList[1]} />
            <SpinOption spins="15" price={addList[2]} />
          </View>
          <View style={styles.optionsRow}>
            <SpinOption spins="20" price={addList[3]} />
            <SpinOption spins="30" price={addList[4]} />
            <SpinOption spins="40" price={addList[5]} />
          </View>
        </View>

        <TouchableOpacity style={styles.buyButton} onPress={pay}>
          <Text style={styles.buyButtonText}>{selectedSpins} Spins</Text>
        </TouchableOpacity>

        <Text style={styles.disclaimerText}>
          Continue to buy means you agree with following
        </Text>
        <View style={styles.termsContainer}>
          <Text style={styles.termsText}>
            Privacy Policy
          </Text>
          <Text style={styles.termsText}> & </Text>
          <Text style={styles.termsText}>
            Terms Of Service
          </Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#E3F2FD',
  },
  scrollContent: {
    alignItems: 'center',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingVertical: 20,
  },
  image: {
    width: '98%',
    resizeMode: 'contain',
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#1976D2',
    marginTop: 20,
  },
  subtitle: {
    fontSize: 18,
    color: '#000000',
    marginTop: 10,
  },
  optionsContainer: {
    width: '100%',
    paddingHorizontal: 10,
    marginTop: 20,
  },
  optionsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  spinOption: {
    width: '30%',
    padding: 10,
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: 15,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
  },
  spinText: {
    fontSize: 26,
    fontWeight: 'bold',
    color: '#000000',
  },
  spinSubText: {
    fontSize: 16,
    color: '#000000',
  },
  priceText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#1976D2',
    marginTop: 5,
  },
  buyButton: {
    width: '90%',
    height: 55,
    backgroundColor: '#1976D2',
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
  },
  buyButtonText: {
    color: '#FFFFFF',
    fontSize: 20,
    fontWeight: 'bold',
  },
  disclaimerText: {
    fontSize: 12,
    color: '#757575',
    marginTop: 20,
  },
  termsContainer: {
    flexDirection: 'row',
    marginTop: 5,
  },
  termsText: {
    fontSize: 13,
    color: '#1976D2',
    fontWeight: 'bold',
  },
});

export default React.memo(AddMoney);
