import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Home, AttachMoney, AddCircleOutline, Security, Description, ExitToApp } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 280,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 280,
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '0 20px 20px 0',
  },
  header: {
    padding: theme.spacing(3),
    backgroundColor: '#1565C0',
  },
  listItem: {
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  icon: {
    color: 'white',
  },
  text: {
    fontWeight: 500,
  },
}));

const AppDrawer = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const name = useSelector((state) => state.game.name);
  const mobileNo = useSelector((state) => state.game.mobileNo);

  const menuItems = [
    { icon: <Home />, title: 'Home', onTap: () => navigate('/') },
    { icon: <AttachMoney />, title: 'Withdraw Coins', onTap: () => navigate('/withdraw') },
    { icon: <AddCircleOutline />, title: 'Add Money', onTap: () => navigate('/add-money') },
    { icon: <Security />, title: 'Privacy Policy', onTap: () => navigate('/privacy') },
    { icon: <Description />, title: 'Terms and Conditions', onTap: () => navigate('/terms') },
    { icon: <ExitToApp />, title: 'Exit', onTap: () => {
      if(window.ExitApp){
        window.ExitApp.postMessage('exit');
      }
    } },
  ];

  return (
    <Drawer
      className={classes.drawer}
      variant="temporary"
      anchor="left"
      open={isOpen}
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Box className={classes.header} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <img src="/assets/images/add_coins.jpg" alt="RozFun" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
        <Typography variant="h6" component="h2" style={{ color: 'white', fontWeight: 'bold', marginTop: '10px' }}>{name || 'Guest'}</Typography>
        <Typography variant="h7" component="h3" style={{ color: 'white', fontWeight: 'normal' }}>+91 {mobileNo || 'No mobile number'}</Typography>
      </Box>
      <List style={{ backgroundColor: 'white' }}>
        {menuItems.map((item, index) => (
          <React.Fragment key={item.title}>
            <ListItem
              button
              onClick={() => {
                item.onTap();
                onClose();
              }}
              style={{
                marginBottom: '0',
                paddingBottom: '4px',
              }}
              className={classes.listItem}
            >
              <ListItemIcon style={{ color: 'black' }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} classes={{ primary: classes.text }} style={{ color: 'black' }} />
            </ListItem>
            {index < menuItems.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
};

export default AppDrawer;
