import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateBalance, updateWin } from '../store/store';
import MarqueeWidget from './marquee';
import WheelPainter from './wheel';
import SpinLogic from '../util/spinLogic';
import { useAudioContext } from '../contexts/AudioContext';
import { useNavigate } from 'react-router-dom';

const SpinWheel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bal, win } = useSelector(state => state.game);
  const [isSpinning, setIsSpinning] = useState(false);
  const [spinValue, setSpinValue] = useState(0);
  const [wheelAngle, setWheelAngle] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const [showResultDialog, setShowResultDialog] = useState(false);
  const [resultPrize, setResultPrize] = useState(0);
  const wheelRef = useRef(null);
  const { playSound, stopAllSounds } = useAudioContext();

  const items = [10, 12, 55, 120, 0, 15, 20, -10, 25, 45, 80, 70, 35, 5, 30, 50];

  useEffect(() => {
    return () => {
      stopAllSounds();
    };
  }, []);

  const spinWheel = () => {
    if (isSpinning) return;
    if (bal < 10) {
      setShowDialog(true);
      return;
    }

    setIsSpinning(true);
    playSound('spin');

    // Deduct 10 points from balance
    dispatch(updateBalance(bal - 10));

    const randomIndex = new SpinLogic().getRandom();
    const spinDuration = 5000;
    const spinRotations = 5 + randomIndex / items.length;

    setSpinValue(spinRotations);
    const angle = Math.round(wheelAngle) + spinRotations;

    if (wheelRef.current) {
      wheelRef.current.style.transition = `transform ${spinDuration}ms cubic-bezier(0.25, 0.1, 0.25, 1)`;
      wheelRef.current.style.transform = `rotate(${angle * 360}deg)`;
      setWheelAngle(angle);
    }

    setTimeout(() => {
      setIsSpinning(false);
      const prize = items[randomIndex];
      // Update winnings
      dispatch(updateWin(win + prize));
      setResultPrize(prize);
      setShowResultDialog(true);
    }, spinDuration);
  };

  const ResultDialog = ({ onClose }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      setIsVisible(true);
      const timer = setTimeout(() => {
        setIsVisible(false);
        setTimeout(onClose, 300); // Wait for animation to finish before closing
      }, 2700);

      return () => clearTimeout(timer);
    }, [onClose]);

    useEffect(() => {
      let soundName;
      if (resultPrize < 0) {
        soundName = 'lost';
      } else if (resultPrize === 130) {
        soundName = 'jackpot';
      } else {
        soundName = 'win';
      }
      playSound(soundName);

      return () => {
        stopAllSounds();
      };
    }, [resultPrize]);

    return (
      <div style={styles.dialogOverlay} onClick={() => setIsVisible(false)}>
        <div 
          style={{
            ...styles.resultDialogContent,
            transform: `translateY(${isVisible ? '0' : '100%'})`,
            transition: 'transform 0.3s ease-out',
          }} 
          onClick={(e) => e.stopPropagation()}
        >
          <img 
            src={`/assets/images/${resultPrize < 0 ? 'sad_img' : (resultPrize === 130 ? 'jacpot' : 'result_showw')}.png`} 
            alt="Result" 
            style={styles.resultImage} 
          />
          <div style={styles.resultTextContainer}>
            <p style={styles.resultText}>
              {resultPrize < 0 
                ? 'Sorry better luck next time' 
                : (resultPrize === 130 
                  ? `Jackpot! You won ${resultPrize}` 
                  : `Congratulation! You won ${resultPrize}`)}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const InsufficientBalanceDialog = ({ onClose }) => (
    <div style={styles.dialogOverlay} onClick={onClose}>
      <div style={styles.dialogContent} onClick={(e) => e.stopPropagation()}>
        <img src="/assets/images/add_coins.jpg" alt="Add Coins" style={styles.dialogImage} />
        <div style={styles.dialogTextContainer}>
          <p style={styles.dialogTitle}>You don't have enough balance to play the wheel!</p>
          <p style={styles.dialogSubtitle}>Please add more balance to Play More.</p>
        </div>
        <button 
          style={styles.addSpinsButton}
          onClick={() => {
            onClose();
            navigate('/add-money');
          }}
        >
          Add Spins
        </button>
      </div>
    </div>
  );

  return (
    <div style={styles.container}>
      <MarqueeWidget />
      <div style={styles.wheelContainer}>
        <div style={styles.wheelRing}></div>
        <div ref={wheelRef} style={styles.wheel}>
          <WheelPainter items={items.map(String)} size={270} />
        </div>
        <div style={styles.spinButton}>
          <img style={styles.spinButtonImage} src="/assets/images/coin_rain.png" alt="spin button" />
        </div>
      </div>
      <button style={styles.spinNowButton} onClick={spinWheel} disabled={isSpinning}>
        <span style={styles.spinNowButtonText}>Spin Now</span>
      </button>
      {showDialog && <InsufficientBalanceDialog onClose={() => setShowDialog(false)} />}
      {showResultDialog && <ResultDialog onClose={() => setShowResultDialog(false)} />}
    </div>
  );
};

const styles = {
  container: {
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%'
  },
  wheelContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wheelRing: {
    backgroundImage: 'url(/assets/images/ring.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '320px',
    height: '310px',
    position: 'absolute',
    borderRadius: '50%',
  },
  wheel: {
    transition: 'transform 0s ease-out',
    height: '270px',
  },
  spinButton: {
    width: 80,
    height: 80,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '30px',
    borderRadius: '50%',
  },
  spinButtonImage: {
    height: '100%',
  },
  spinNowButton: {
    backgroundColor: '#1976D2',
    padding: '15px 50px',
    borderRadius: 25,
    border: 'none',
    cursor: 'pointer',
  },
  spinNowButtonText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'white',
  },
  dialogOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogContent: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '80%',
  },
  dialogImage: {
    width: '100%',
    maxWidth: '300px',
    marginBottom: '10px',
  },
  dialogTextContainer: {
    textAlign: 'center',
    marginBottom: '10px',
  },
  dialogTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  dialogSubtitle: {
    fontSize: '12px',
  },
  addSpinsButton: {
    backgroundColor: 'green',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
  },
  resultDialogContent: {
    backgroundColor: '#1976D2',
    borderTopLeftRadius: '25px',
    borderTopRightRadius: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    height: '280px',
    transform: 'translateY(100%)', // Start off-screen
  },
  resultImage: {
    height: '200px',
    width: '200px',
    marginTop: '20px',
  },
  resultTextContainer: {
    backgroundColor: 'red',
    width: '100%',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },
  resultText: {
    color: 'white',
    fontSize: '20px',
    fontWeight: 'bold',
  },
};

export default SpinWheel;
