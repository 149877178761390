import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import WalletBalance from '../components/balance';
import SpinWheel from '../components/spinWheel';
import AppDrawer from '../components/drawer';

const SpinTheWheelGame = () => {
  const [balance, setBalance] = useState(100);
  const [winnings, setWinnings] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const items = [100, 50, 130, -20, 40, -50, 120, 60, -40, 50, 100, 40];

  useEffect(() => {
    const handleBackButton = (e) => {
      e.preventDefault();
      onBackPressed();
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const onBackPressed = () => {
    // Handle back navigation logic here
    console.log('Back button pressed');
  };

  const updateWinnings = (index) => {
    setWinnings(prev => prev + items[index]);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div style={styles.container}>
      <AppDrawer
        isOpen={isDrawerOpen}
        onClose={toggleDrawer}
        navigate={navigate}
      />
      <div style={styles.header}>
        <button onClick={toggleDrawer} style={styles.menuButton}>
          ☰
        </button>
        <h1 style={styles.headerTitle}>RozFun</h1>
      </div>
      <div style={styles.contentContainer}>
        <div style={styles.content}>
          <WalletBalance balance={balance} winnings={winnings} />
          <SpinWheel items={items} onSpinEnd={updateWinnings} />
          <img src="/assets/images/india_logo.png" alt="India Logo" style={styles.indiaLogo} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  header: {
    height: '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#1976D2',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    position: 'relative',
  },
  headerTitle: {
    fontSize: '32px',
    fontWeight: '500',
    color: 'white',
  },
  menuButton: {
    position: 'absolute',
    left: '10px',
    background: 'none',
    border: 'none',
    color: 'white',
    fontSize: '32px',
    cursor: 'pointer',
  },
  contentContainer: {
    flex: 1,
    backgroundImage: 'url("/assets/images/main_bg.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // This creates a semi-transparent white overlay
    backgroundBlendMode: 'overlay', // This blends the background image with the overlay
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
  indiaLogo: {
    height: '40px',
    marginTop: '5px',
    marginBottom: '5px',
  },
};

export default SpinTheWheelGame;
