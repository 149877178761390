import React, { createContext, useContext, useEffect, useRef } from 'react';

const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
  const audioRefs = useRef({});

  useEffect(() => {
    // Preload audio files
    const audioFiles = {
      spin: '/assets/audio/spin_sound.mp3',
      win: '/assets/audio/won.mp3',
      jackpot: '/assets/audio/jackpot.mp3',
      lost: '/assets/audio/lost.mp3',
    };

    Object.entries(audioFiles).forEach(([key, src]) => {
      const audio = new Audio(src);
      audio.preload = 'auto';
      audioRefs.current[key] = audio;
    });

    return () => {
      // Cleanup audio objects
      // Object.values(audioRefs.current).forEach(audio => {
      //   audio.pause();
      //   audio.src = '';
      // });
    };
  }, []);

  const playSound = (soundName) => {
    const audio = audioRefs.current[soundName];
    if (audio) {
      audio.currentTime = 0;
      audio.play().catch(error => console.error("Error playing sound:", error));
    }
  };

  const stopAllSounds = () => {
    // Object.values(audioRefs.current).forEach(audio => {
    //   audio.pause();
    //   audio.currentTime = 0;
    // });
  };

  return (
    <AudioContext.Provider value={{ playSound, stopAllSounds }}>
      {children}
    </AudioContext.Provider>
  );
};

export const useAudioContext = () => useContext(AudioContext);
