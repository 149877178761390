import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateBalance, updateWin } from '../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faWallet, faRupeeSign } from '@fortawesome/free-solid-svg-icons';

const Withdraw = () => {
  const dispatch = useDispatch();
  const { bal, win } = useSelector(state => state.game);
  const [paymentOption, setPaymentOption] = useState(1);
  const [paytmNumber, setPaytmNumber] = useState('');
  const [upiId, setUpiId] = useState('');
  const [amount, setAmount] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      alert("You don't have enough Coins to Withdraw");
    }
  };

  const validateForm = () => {
    if (paymentOption === 0) {
      if (!paytmNumber || paytmNumber.length !== 10) {
        return false;
      }
    } else {
      if (!upiId || !/[a-zA-Z0-9.-_]{2,256}@[a-zA-Z]{2,64}/.test(upiId)) {
        return false;
      }
    }
    if (!amount || parseInt(amount) < 500) {
      return false;
    }
    return true;
  };

  const goBack = () => {
    window.history.back();
  }

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <button onClick={goBack} style={styles.backButton}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <h1 style={styles.headerTitle}>Withdraw</h1>
      </header>
      
      <main style={styles.main}>
        <div style={styles.walletCard}>
          <div style={styles.walletInfo}>
            <FontAwesomeIcon icon={faWallet} style={styles.walletIcon} />
            <div>
              <h2 style={styles.walletTitle}>My Wallet</h2>
              <p style={styles.walletSubtitle}>Earned Amount</p>
            </div>
          </div>
          <div style={styles.amountDisplay}>
            <FontAwesomeIcon icon={faRupeeSign} style={styles.rupeeIcon} />
            <span style={styles.amount}>{win}</span>
          </div>
        </div>

        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.paymentOptions}>
            <button 
              type="button"
              onClick={() => setPaymentOption(0)}
              style={{...styles.paymentButton, ...(paymentOption === 0 ? styles.activePaymentButton : {})}}
            >
              <img src="/assets/images/paytm.png" alt="Paytm" style={styles.paymentIcon} />
            </button>
            <button 
              type="button"
              onClick={() => setPaymentOption(1)}
              style={{...styles.paymentButton, ...(paymentOption === 1 ? styles.activePaymentButton : {})}}
            >
              <img src="/assets/images/upi.png" alt="UPI" style={styles.paymentIcon} />
            </button>
          </div>

          <div style={styles.inputContainer}>
            {paymentOption === 0 ? (
              <input
                type="tel"
                value={paytmNumber}
                onChange={(e) => setPaytmNumber(e.target.value)}
                placeholder="Enter PayTm Number"
                style={styles.input}
              />
            ) : (
              <input
                type="text"
                value={upiId}
                onChange={(e) => setUpiId(e.target.value)}
                placeholder="Enter UPI ID"
                style={styles.input}
              />
            )}
          </div>

          <div style={styles.inputContainer}>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter Amount"
              style={styles.input}
            />
          </div>

          <p style={styles.minAmount}>Minimum Withdraw Amount is ₹500</p>

          <button type="submit" style={styles.submitButton}>WITHDRAW</button>
        </form>
      </main>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    background: 'linear-gradient(90deg, #1976D2 0%, #2196F3 100%)',
    color: '#FFFFFF',
    position: 'relative',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  backButton: {
    background: 'none',
    border: 'none',
    color: 'white',
    fontSize: '24px',
    cursor: 'pointer',
    marginRight: '20px',
  },
  headerTitle: {
    fontSize: '22px',
    fontWeight: '600',
    margin: 0,
  },
  main: {
    flex: 1,
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  walletCard: {
    background: 'linear-gradient(135deg, rgb(47 193 255) 0%, rgb(19 112 234) 100%)',
    borderRadius: '15px',
    padding: '25px',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
    color: '#FFFFFF',
  },
  walletInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  walletIcon: {
    fontSize: '28px',
    marginRight: '15px',
  },
  walletTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: 0,
  },
  walletSubtitle: {
    fontSize: '14px',
    opacity: 0.8,
    margin: '5px 0 0',
  },
  amountDisplay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '10px',
    padding: '15px',
  },
  rupeeIcon: {
    marginRight: '8px',
  },
  amount: {
    fontSize: '28px',
    fontWeight: 'bold',
  },
  form: {
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: '25px',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.05)',
  },
  paymentOptions: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '25px',
  },
  paymentButton: {
    background: 'none',
    border: '2px solid #E0E0E0',
    borderRadius: '12px',
    padding: '12px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  },
  activePaymentButton: {
    borderColor: '#1976D2',
    boxShadow: '0 0 0 3px rgba(25, 118, 210, 0.2)',
  },
  paymentIcon: {
    height: '35px',
  },
  inputContainer: {
    marginBottom: '20px',
    position: 'relative',
  },
  input: {
    width: 'calc(100% - 30px)',
    padding: '15px',
    borderRadius: '12px',
    border: '2px solid #E0E0E0',
    fontSize: '16px',
    transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
    outline: 'none',
  },
  minAmount: {
    fontSize: '14px',
    color: '#666',
    textAlign: 'center',
    margin: '15px 0',
  },
  submitButton: {
    width: '100%',
    padding: '15px',
    background: 'linear-gradient(90deg, #1976D2 0%, #2196F3 100%)',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '12px',
    cursor: 'pointer',
    fontSize: '18px',
    fontWeight: 'bold',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
};

export default Withdraw;
